import axios from "axios";
// review
export const REVIEW = 'REVIEW';

const reviewSuccess = (data) => ({
    type:REVIEW,
    reviewAction:data
});

export const showReview = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review');
            dispatch(reviewSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountAll
export const REVIEW_COUNT_ALL = 'REVIEW_COUNT_ALL';

const reviewCountAllSuccess = (data) => ({
    type:REVIEW_COUNT_ALL,
    reviewCountAllAction:data
});

export const showReviewCountAll = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcountall');
            dispatch(reviewCountAllSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountOne
export const REVIEW_COUNT_ONE = 'REVIEW_COUNT_ONE';

const reviewCountOneSuccess = (data) => ({
    type:REVIEW_COUNT_ONE,
    reviewCountOneAction:data
});

export const showReviewCountOne = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcountone');
            dispatch(reviewCountOneSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountTwo
export const REVIEW_COUNT_TWO = 'REVIEW_COUNT_TWO';

const reviewCountTwoSuccess = (data) => ({
    type:REVIEW_COUNT_TWO,
    reviewCountTwoAction:data
});

export const showReviewCountTwo = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcounttwo');
            dispatch(reviewCountTwoSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountThree
export const REVIEW_COUNT_THREE = 'REVIEW_COUNT_THREE';

const reviewCountThreeSuccess = (data) => ({
    type:REVIEW_COUNT_THREE,
    reviewCountThreeAction:data
});

export const showReviewCountThree = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcountthree');
            dispatch(reviewCountThreeSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountFour
export const REVIEW_COUNT_FOUR = 'REVIEW_COUNT_FOUR';

const reviewCountFourSuccess = (data) => ({
    type:REVIEW_COUNT_FOUR,
    reviewCountFourAction:data
});

export const showReviewCountFour = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcountfour');
            dispatch(reviewCountFourSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
// reviewCountFive
export const REVIEW_COUNT_FIVE = 'REVIEW_COUNT_FIVE';

const reviewCountFiveSuccess = (data) => ({
    type:REVIEW_COUNT_FIVE,
    reviewCountFiveAction:data
});

export const showReviewCountFive = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewcountfive');
            dispatch(reviewCountFiveSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}

// learnedWords
export const LEARNED_WORDS = 'LEARNED_WORDS';

const learnedWordsSuccess = (data) => ({
    type:LEARNED_WORDS,
    learnedWordsAction:data
});

export const learnedWords = (page,search,user_id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/review/learnedwords?page=${page}&search=${search}&user_id=${user_id}`);
            dispatch(learnedWordsSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
//reviewVocab 
export const REVIEW_VOCAB = 'REVIEW_VOCAB';

const reviewVocabSuccess = (data) => ({
    type:REVIEW_VOCAB,
    reviewVocabAction:data
});
export const reviewVocab = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewvocab');
            dispatch(reviewVocabSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
//reviewGrammar 
export const REVIEW_GRAMMAR = 'REVIEW_GRAMMAR';

const reviewGrammarSuccess = (data) => ({
    type:REVIEW_GRAMMAR,
    reviewGrammarAction:data
});
export const reviewGrammar = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/reviewgrammar');
            dispatch(reviewGrammarSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
//updateReview
export const UPDATE_REVIEW = 'UPDATE_REVIEW';

const updateReviewSuccess = (data) => ({
    type:UPDATE_REVIEW,
    updateReviewAction:data
});
export const updateReview = (reviews) => {
    return async (dispatch) => {
        try {
            // const reviewSelect = review.map(({review_id}) => ({review_id}))
            // console.log(reviewSelect);
            const response = await axios.put('/review/updatereview',{reviews});
            dispatch(updateReviewSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
//lessonVocabDone 
export const LESSON_VOCAB_DONE = 'LESSON_VOCAB_DONE';

const lessonVocabDoneSuccess = (data) => ({
    type:LESSON_VOCAB_DONE,
    lessonVocabDoneAction:data
});
export const lessonVocabDone = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/lessonvocabdone');
            dispatch(lessonVocabDoneSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}
//lessonGrammarDone 
export const LESSON_GRAMMAR_DONE = 'LESSON_GRAMMAR_DONE';

const lessonGrammarDoneSuccess = (data) => ({
    type:LESSON_GRAMMAR_DONE,
    lessonGrammarDoneAction:data
});
export const lessonGrammarDone = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('/review/lessongrammardone');
            dispatch(lessonGrammarDoneSuccess(response.data));
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu"+ error);
        }
    }
}