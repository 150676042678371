import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { lessonVocabDone } from '../react-redux/actions/reviewAction';

class LessonVocabMember extends Component {
    componentDidMount() {
        if (this.props.review.lessonVocabDoneData === null) {
            this.props.lessonVocabDone();
        }
    }
    showlesson = () => {
        if (this.props.review.lessonVocabDoneData !== null) {
            const lessonVocabDone = this.props.review.lessonVocabDoneData.filter(value => value.user_id === this.props.user_id && value.lesson_name === this.props.lesson);
            if (lessonVocabDone.length > 0) {
                return (
                    <Link to={this.props.linkto} className="btn btn-primary">
                        <h2>{this.props.lesson}</h2>
                        <img
                            src="/assets/image/completed.png"
                            className="img-fluid rounded-top complete"
                            alt=""
                        />
                    </Link>
                )
            } else {
                return (
                    <Link to={this.props.linkto} className="btn btn-primary">
                        <h2>{this.props.lesson}</h2>
                    </Link>
                )
            }
        }
    }
    render() {
        return (
            <div className="col-10">
                <div className="d-grid gap-2 mb-3">
                    {this.showlesson()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        review: state.review
    }
}
const mapDispatchToProps = {
    lessonVocabDone
}
export default connect(mapStateToProps, mapDispatchToProps)(LessonVocabMember);