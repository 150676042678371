import {  REVIEW, LEARNED_WORDS, REVIEW_GRAMMAR, REVIEW_VOCAB, UPDATE_REVIEW, LESSON_VOCAB_DONE, LESSON_GRAMMAR_DONE, REVIEW_COUNT_ALL, REVIEW_COUNT_ONE, REVIEW_COUNT_TWO, REVIEW_COUNT_THREE, REVIEW_COUNT_FOUR, REVIEW_COUNT_FIVE } from "../actions/reviewAction"

const InitialState = {
    reviewData: null,
    reviewCountAllData: null,
    reviewCountOneData: null,
    reviewCountTwoData: null,
    reviewCountThreeData: null,
    reviewCountFourData: null,
    reviewCountFiveData: null,
    learnedWordsData: null,
    reviewVocabData: null,
    reviewGrammarData: null,
    updateReviewData: null,
    lessonVocabDoneData: null,
    lessonGrammarDoneData: null
}
const reviewReducer = (state = InitialState, action) => {
    switch (action.type) {
        case REVIEW:
            return { ...state, reviewData: action.reviewAction }
        case REVIEW_COUNT_ALL:
            return { ...state, reviewCountAllData: action.reviewCountAllAction }
        case REVIEW_COUNT_ONE:
            return { ...state, reviewCountOneData: action.reviewCountOneAction }
        case REVIEW_COUNT_TWO:
            return { ...state, reviewCountTwoData: action.reviewCountTwoAction }
        case REVIEW_COUNT_THREE:
            return { ...state, reviewCountThreeData: action.reviewCountThreeAction }
        case REVIEW_COUNT_FOUR:
            return { ...state, reviewCountFourData: action.reviewCountFourAction }
        case REVIEW_COUNT_FIVE:
            return { ...state, reviewCountFiveData: action.reviewCountFiveAction }
        case LEARNED_WORDS:
            return { ...state, learnedWordsData: action.learnedWordsAction }
        case REVIEW_VOCAB:
            return { ...state, reviewVocabData: action.reviewVocabAction }
        case REVIEW_GRAMMAR:
            return { ...state, reviewGrammarData: action.reviewGrammarAction }
        case UPDATE_REVIEW:
            return { ...state, updateReviewData: action.updateReviewAction }
        case LESSON_VOCAB_DONE:
            return { ...state, lessonVocabDoneData: action.lessonVocabDoneAction }
        case LESSON_GRAMMAR_DONE:
            return { ...state, lessonGrammarDoneData: action.lessonGrammarDoneAction }
        default:
            return state
    }
}
export default reviewReducer