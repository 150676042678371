import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confirm } from '../react-redux/actions/signUpAction';
import withRouter from '../router/withRouter';
import { Link } from 'react-router-dom';

class Confirm extends Component {
    componentDidMount() {
        const token = new URLSearchParams(this.props.locations.search).get('token');
        this.props.confirm(token,this.props.navigates);
    }
    
    render() {
        return (
            <div className='loading'>
                <img
                    src="/assets/image/logo.svg"
                    className="img-fluid rounded-top icon_logo_load"
                    alt=""
                />
                <h3>Đang xử lý xác nhận</h3>
                <h4 className='loadError'>Xác nhận thất bại do phiên hết hạn. Vui lòng 
                    <Link to="/signup"> Đăng ký </Link>
                    lại để tham gia học
                </h4>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        signup: state.signup
    }
}
const mapDispatchToProps =  {
    confirm
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Confirm));