import React, { Component } from 'react';

class TableManagerCommunication extends Component {
    button_sound_click = () => {
        var button_sound_click = document.querySelectorAll('.fa-volume-high');
        for (var i = 0; i < button_sound_click.length; i++) {
            button_sound_click[i].onclick = function () {
                var audio = document.querySelector('.audios');
                var source = document.querySelector('source')
                source.src = this.getAttribute('data-srcAudio')
                audio.load();
                audio.play();
            }
        }
    }
    render() {
        return (
            <tr>
                <td>{this.props.stt}</td>
                <td>{this.props.lesson_name}</td>
                <td>
                    <audio className="audios" autoPlay>
                        <source src='' type="audio/mpeg" />
                    </audio>
                    <i className="fa-solid fa-volume-high" data-srcaudio={this.props.sound_shadowing} onClick={() => this.button_sound_click()} />
                </td>
                <td>{this.props.mean_shadowing}</td>
                <td className="action">
                    <i className="fa-solid fa-circle-info" data-bs-toggle="modal"
                        data-bs-target="#communicationInfo" onClick={this.props.clickIconInfo}></i>
                    <i className="fa-solid fa-delete-left" onClick={this.props.clickIconDelete}></i>
                </td>
            </tr>
        );
    }
}

export default TableManagerCommunication;