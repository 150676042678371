import React, { Component } from 'react';

class ModelInfoVocab extends Component {
    button_sound_click = () => {
        var button_sound_click = document.querySelectorAll('.fa-volume-high');
        for (var i = 0; i < button_sound_click.length; i++) {
            button_sound_click[i].onclick = function () {
                var audio = document.querySelector('.audios');
                var source = document.querySelector('source')
                source.src = this.getAttribute('data-srcAudio')
                audio.load();
                audio.play();
            }
        }
    }
    render() {
        return (
            <div className="modal" tabIndex={-1} id="vocabInfo">
                <div className="modal-dialog  modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Từ vựng chi tiết</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <ul>
                                <li>{'Tên: ' + this.props.name}</li>
                                <li>{'Âm thanh: '}
                                    <audio className="audios" autoPlay>
                                        <source src='' type="audio/mpeg" />
                                    </audio>
                                    <i className="fa-solid fa-volume-high" data-srcaudio={"/sound/" + this.props.sound} onClick={() => this.button_sound_click()} />
                                </li>
                                <li>{'Âm hán việt: ' + this.props.sino_vietnamese_sound}</li>
                                <li>{'Cách đọc: ' + this.props.pronunciation}</li>
                                <li>{'Ý nghĩa ví dụ: ' + this.props.example_mean}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModelInfoVocab;