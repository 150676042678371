import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => {
  function WithRouterProp(props) {
    let params = useParams();
    let locations = useLocation();
    let navigates = useNavigate()
    return (
      <Component
        {...props}
        params={params}
        locations={locations}
        navigates={navigates}
      />
    );
  }

  return WithRouterProp;
};

export default withRouter;
