import React, { Component } from 'react';

class TableManagerAlphabet extends Component {
    button_sound_click = () => {
        var button_sound_click = document.querySelectorAll('.fa-volume-high');
        for (var i = 0; i < button_sound_click.length; i++) {
            button_sound_click[i].onclick = function () {
                var audio = document.querySelector('.audios');
                var source = document.querySelector('source')
                source.src = this.getAttribute('data-srcAudio')
                audio.load();
                audio.play();
            }
        }
    }
    render() {
        return (
            <tr>
                <td>{this.props.stt}</td>
                <td>{this.props.name}</td>
                <td>{this.props.pronunciation}</td>
                <td>
                    {this.props.example}
                </td>
                <td>
                    <audio className="audios" autoPlay>
                        <source src='' type="audio/mpeg" />
                    </audio>
                    <i className="fa-solid fa-volume-high" data-srcaudio={this.props.sound} onClick={() => this.button_sound_click()} />
                </td>
                <td className="type_alphabet">{this.props.type}</td>
                <td className="lesson_name_alphabet">
                    {this.props.lesson_name}
                </td>
                <td className="action">
                    <i className="fa-solid fa-delete-left" onClick={this.props.
                        clickIconDelete}></i>
                </td>
            </tr>
        );
    }
}

export default TableManagerAlphabet;