import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NoLesson extends Component {
    render() {
        return (
            <div className='NoLesson'>
                <h1>Hiện tại chưa có bài học</h1>
                <p>Sẽ cập nhập bài học trong thời gian sớm nhất</p>
                <Link to = {this.props.linkto}>
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>
            </div>
        );
    }
}

export default NoLesson;